
import { ref, onMounted, defineComponent } from 'vue';

const randRange = (min: number, max: number) =>
  min >= max ? min : Math.floor(Math.random() * (max - min + 1)) + min;

const flip = () => Math.random() < 0.5;
const randSign = (num: number) => (flip() ? num * 1 : num * -1);

const minSpeed = 2;
const maxSpeed = 15;

export default defineComponent({
  props: {
    shouldFade: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const root = ref<HTMLElement | null>(null);
    const el = ref<HTMLCanvasElement | null>(null);

    const item = {
      img: new Image(),
      fading: true,
      opacity: 3,
      // rotationRate: 0.1,
      rotationRate: 0,
      rotation: 0,
      x: 0,
      y: 0,
      width: 285,
      height: 100,
      xspeed: randSign(randRange(minSpeed, maxSpeed)),
      yspeed: randSign(randRange(minSpeed, maxSpeed)),
    };
    onMounted(() => {
      if (!root.value || !el.value) return;
      let width = root.value.offsetWidth;
      let height = root.value.offsetHeight;
      item.x = randRange(0, width - item.width);
      item.y = randRange(0, height - item.height);
      const canvas = el.value;
      const ctx = canvas.getContext('2d');

      function onResize() {
        if (!root.value) return;
        height = root.value.offsetHeight;
        width = root.value.offsetWidth;
        canvas.setAttribute('width', width.toString());
        canvas.setAttribute('height', height.toString());
      }

      function checkHitBox() {
        let hit = false;
        if (item.x + item.width >= width) {
          item.xspeed = -randRange(minSpeed, maxSpeed);
          item.yspeed *= flip() ? -1 : 1;
          hit = true;
        } else if (item.x <= 0) {
          item.xspeed = randRange(minSpeed, maxSpeed);
          item.yspeed *= flip() ? -1 : 1;
          hit = true;
        }
        if (item.y + item.height >= height) {
          item.yspeed = -randRange(minSpeed, maxSpeed);
          item.xspeed *= flip() ? -1 : 1;
          hit = true;
        } else if (item.y <= 0) {
          item.yspeed = randRange(minSpeed, maxSpeed);
          item.xspeed *= flip() ? -1 : 1;
          hit = true;
        }
        if (hit) {
          item.rotationRate *= flip() ? -1 : 1;
        }
      }

      function draw() {
        requestAnimationFrame(draw);
        if (!ctx) return;
        ctx.clearRect(0, 0, width, height);
        ctx.filter = `hue-rotate(${item.rotation * 10}deg)`;
        ctx.globalAlpha = item.opacity;
        ctx.setTransform(
          1,
          0,
          0,
          1,
          item.x + item.width / 2,
          item.y + item.height / 2
        );

        ctx.rotate(item.rotation);
        ctx.drawImage(
          item.img,
          -item.width / 2,
          -item.height / 2,
          item.width,
          item.height
        );
        ctx.setTransform(1, 0, 0, 1, 0, 0); // restore default transform

        item.x += item.xspeed;
        item.y += item.yspeed;
        item.rotation += item.rotationRate;
        if (props.shouldFade) {
          if (item.fading) {
            item.opacity -= 0.01;
            if (item.opacity <= -0.5) item.fading = false;
          } else {
            item.opacity += 0.01;
            if (item.opacity >= 3) item.fading = true;
          }
        }

        checkHitBox();
      }

      function start() {
        if (ctx) {
          window.addEventListener('resize', onResize);
          onResize();
          draw();
        }
      }

      item.img.onload = () => {
        start();
      };
      item.img.src = 'basedmax.svg';
    });
    return { root, el };
  },
});
